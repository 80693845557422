import React from 'react'
import { Link } from 'gatsby'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item home is-uppercase has-text-weight-bold" title="Logo">
            Shira Eini Pindyck
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${this.state.navBarActiveClass}`}
            data-target="navMenu"
            onClick={() => this.toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div
          id="navMenu"
          className={`navbar-menu ${this.state.navBarActiveClass}`}
        >
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/teaching">
              Teaching
            </Link>
            <Link className="navbar-item" to="/cv">
              CV
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link className="navbar-link" to="/research/dissertation-project">Research &amp; Data</Link>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/research/dissertation-project">
                  Book Project
                </Link>
                <Link className="navbar-item" to="/research/institutional-commitment-to-inclusion">
                  Institutional Commitment to Inclusion
                </Link>
              </div>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
